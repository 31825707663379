// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { Link } from 'react-router-dom';
    import { scrollToTop } from '../js/Functions';
// -----------------------------------------------------------------------------


class FooterText extends React.Component{
    render(){
        const date = new Date().getFullYear();
        return(
            <>
                <div className="col-1-2 left">
                    All content &copy;{date} Moonspace<br />
                    <Link to="/terms" onClick={() => scrollToTop()}>Terms, conditions &amp; privacy</Link>
                </div>
                <div className="col-1-2 right">
                    BroadstoneUK Ltd trading as MoonSpace<br />
                    Company number: 4954763 Registered in England &amp; Wales<br />
                    Crugiau Isaf, Pentregalar, Crymych, Pembrokeshire, SA41 3QP
                </div>
            </>
        )
    }
}

export default FooterText;
