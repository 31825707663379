// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { Link } from 'react-router-dom';
    import { scrollToTop } from '../../js/Functions';
// -----------------------------------------------------------------------------

class About extends React.Component{
    render(){
        return(
            <>
                <h1>About Moonspace</h1>

                <p>Moonspace are a dynamic web development company based in Pembrokeshire.</p>

                <p>We have over ten years experience in web development and have worked with such companies as Dyson, nPower &amp; Prudential.</p>

                <p>We offer a full range of services from the beginning to the end of the website development process.</p>

                <ul>
                	<li>Website development</li>
                	<li>Website design</li>
                	<li>Email</li>
                	<li>Search engine optimisation</li>
                	<li>Website management</li>
                	<li>Development for mobile as a default</li>
                </ul>

                <p>We operate a transparent development process where our customers are included in the process at all stages. This protects against suprises and allows our customers to become part of the process of development.</p>

                <p>Unlike many development companies we don't use 'What you see is what you get' (WYSIWYG') tools to develop websites. All of our sites are hand-coded. In this way we ensure that the code we produce is lightweight and clean, which is beneficial for search engines and mobile applications.</p>

                <p>When it comes to determining specifications for a new website, we operate an 'ask us' policy. In other words, we don't tell you what we can do, rather we ask you what you want your website to do. In this way you don't end up with a 'standard' off the shelf site and we develop new skills.</p>

                <p>We make use of a number of technologies in the development process:</p>

                <ul>
                	<li>PHP</li>
                	<li>Javascript</li>
                	<li>jQuery</li>
                	<li>SQL</li>
                	<li>MySQL</li>
                	<li>AJAX</li>
                	<li>HTML</li>
                	<li>CSS &amp; SCSS/SASS</li>
                </ul>

                <p>In order to 'demystify' the development process, we have produced a 'Website basics' guide to explain the basics of, not only websites, but also the development process of building a site.</p>

                <Link to="/websiteBasics" className="link icon-click" onClick={() => scrollToTop()}>Click here to view the basics of websites and development</Link>
            </>
        )
    }
}

export default About;
