// -- Setup --------------------------------------------------------------------
    import React from 'react';
// -----------------------------------------------------------------------------

class WebsiteBasics extends React.Component{
    render(){
        return(
            <>
                <h1>All about web development</h1>

                <p>The world of web deign can be a bewildering and confusing thing for a customer. With this in mind we have put this guide together to guide you through some of the main points. Hopefully, after reading this you will know what you need to do , what Moonspace can do for you and what to expect when your website is being developed.</p>

                <h2>Types of websites</h2>

                <p>There are many &lsquo;types&rsquo; of websites:</p>

                <ul>
                    <li>Shopping sites</li>
                    <li>Information sites</li>
                    <li>Directories</li>
                    <li>Advertising sites</li>
                </ul>

                <p>The list is endless . . .</p>

                <p>To simplify this confusion, there are really only two types of website:</p>

                <ul>
                    <li><strong>Static</strong> - Sites where the information cannot be changed by the site owner. e.g. Brochure sites, advertising sites</li>
                    <li><strong>Dynamic</strong> - Sites that include a database which can be updated by the site owner. e.g. Shopping sites, information sites</li>
                </ul>

                <p>As a general rule of thumb, static sites are cheaper and dynamic sites are more expensive. This is because of the development time involved in a dynamic site. Static sites can be developed quickly and dynamic sites take a little longer.</p>


                <h2>What's needed for a website ?</h2>

                <p>There are many things that need to be in place for a website to be publicly available, the good news is <em>most</em> of these are handled by us. All you really need is an idea and domain name (although we can help with this). Moonspace can guide you through the purchasing of domain names.</p>

                <h3>Domain names</h3>

                <p>A good place to start, once you've had your idea, is a domain name. The domain name can be a powerful tool when it comes to getting your website to the top of search engines. It should describe your business and be easy to remember.</p>

                <p><strong>Good domain names</strong></p>
                <ul>
                    <li className="noLi">
                        <ul>
                            <li>www.bookshop.co.uk (owned by W H Smith)</li>
                            <li>www.amazon.co.uk (simple name and a known brand)</li>
                            <li>www.marksandspencer.com (an established brand - the website name is descriptive)</li>
                        </ul>
                    </li>
                </ul>

                <p><strong>Bad domain names</strong></p>
                <ul>
                    <li className="noLi">
                        <ul>
                            <li>www.buy-bargain-books-online.co.uk (to many dashes, not easy to read out loud - try it !)</li>
                            <li>www.myonlineshop.co.uk (isn't descriptive of the product or the company name (unless your company name is 'my online shop' !))</li>
                            <li>www.auctionshit.com (the company is Auctions Hit but, the domain name may be read differently . . .)</li>
                        </ul>
                    </li>
                </ul>

                <p><strong>Here are a few useful facts about domain names:</strong></p>

                <ul>
                    <li>If your domain name is available, any legitimate reseller (known as a <em>registrar</em>) will sell it.</li>
                    <li>Domain names have a pretty much fixed price:</li>
                    <li className="noLi">
                        <ul>
                            <li>'.co.uk', '.me.uk', '.org.uk' - around &pound;14.00 for two years</li>
                            <li>'.com', '.org', '.net' - around &pound;24.00 for two years</li>
                            <li>The longer the period you buy a domain name for, the cheaper it is</li>
                            <li>Don't be fooled by companies offering to secure your domain name for &pound;100's of pounds !</li>
                        </ul>
                    </li>
                    <li>You can buy your domain name before your do anything, for &pound;14.00 it's worth securing the name sooner rather than later.</li>
                    <li>Domain names can be bought for a minimum of two years with options for up to ten years.</li>
                    <li>Once you've bought a domain name, as long as you renew it on time, it's yours for life.</li>
                    <li>You can have more than one domain name pointing at the same website. For example: www.bookshop.co.uk goes to www.whsmith.co.uk.</li>
                    <li>If you buy a domain name, you also get access to email addresses associated with that domain name. For example, if you buy 'www.mywebsite.co.uk' you can have as many email addresses as you want ending with '@mywebsite.co.uk' (e.g. contact@mywebsite.co.uk, john@mywebsite.co.uk, jane@mywebsite.co.uk).</li>
                    <li>If you buy a domain name, you can also use &lsquo;Sub&rsquo; domains. For example, if you buy www.mywebsite.co.uk, you can also use &lsquo;http://portfolio.mywebsite.co.uk&rsquo;, &lsquo;http://gallery.mywebsite.co.uk&rsquo;, &lsquo;http://music.mywebsite.co.uk&rsquo; - the possibilities are endless.</li>
                </ul>

                <p>We advise our customers to use <a href="http://www.123-reg.co.uk" target="_blank">www.123-reg.co.uk</a> for domain name purchases.</p>

                <h3>Web Hosting</h3>

                <p>For a website to be available to the public 24 hours a day 7 days a week, it needs to be hosted on a web-server. A web server is a special computer that is permanently connected to the internet. The website is 'stored' on the web-server and when a visitor types in your domain name, he or she is accessing the web-server.</p>

                <p>Moonspace handle everything to do with the web-server, so there's no need to worry about it. We make a yearly charge for hosting and maintenance which ensures that your website is always available and up to date. Our web-servers are monitored 24 hours a day and are regularly backed up. We can use any existing web hosting that you may have although we will charge an addition hourly rate to keep the website up to date and cannot guarantee that the website will always be available.</p>

                <p>Along with web hosting comes email hosting. This is also handled by the web-server and we can set up as many email accounts as you need. These can then be access via an email client (e.g. Outlook, Thunderbird) or via a special 'webmail' interface from any computer.</p>


                <h2>Website Design</h2>

                <p>There are a number of aspects to the design of the website, how it looks, how it works and how other people use it...</p>

                <p>
                    <strong>How it looks</strong><br />
                    How a website looks can make or break the website. A website can have stunningly useful information or a shop can be packed with great products, but if the website looks unprofessional, customers will leave before they realise what the site has to offer !
                </p>

                <p>
                    <strong>How it works</strong><br />
                    There are many aspects of web design that are hidden to the casual visitor. Like all computer languages, those used in web sites can be written badly. The languages of web design are very forgiving, sites can have many errors which the visitor isn't aware of. These errors do make a difference when it comes to getting the web site high into the search engines. Search engines give a higher ranking to those sites that use &lsquo;valid&rsquo; code. There are computer tools out there that will produce a website for you without requiring any technical knowledge. These, however nearly always produce invalid code. We at Moonspace hand code every site we produce. This means that all the code Moonspace writes is valid and if something goes wrong, we know how to fix it.
                </p>

                <p>
                    <strong>How other people use it</strong><br />
                    This is summed up as &lsquo;usability&rsquo; (or &rsquo;UX&lsquo;, &rsquo;UI&lsquo;)  and means &lsquo;is the site easy to use&rsquo; ? Your website may be packed with great information or your shop could be full of great products but if a user can't find their way around the site, they'll never find them ! What's the point of that ? How the website looks and how people use it go hand in hand. Moonspace always design websites to be as &lsquo;useable&lsquo; as possible and we apply this principle from the early design stages.
                </p>

                <h3>The Design</h3>

                <p>There are a number of ways that we work when it comes to the graphic design of a website.</p>

                <ul>
                    <li>You may already have a company brand that is reflected in brochures or other print media. If this is the case then we suggest that you continue this brand to the website. We can quite happily work from a brochure.</li>
                    <li>You may be having a new brand designed by a specialist graphic designer. If this is the case, ask them to design a website layout for you. Moonspace are happy to work with a graphic designer.</li>
                    <li>Graphic designers can charge large fees so you may be doing the design yourself. Moonspace are happy to work from your own designs whether they're drawings, sketches or merely a concept.</li>
                    <li>If you have no idea what you want the website to look like, Moonspace can help. We suggest that you browse the internet and find some websites that you like the look of. For example, you may like the colour of site 'a', the layout of site 'b' and the menu of site 'c'. Using these examples we can create an original design based on the aspects you like.</li>
                </ul>

                <p>Some website design companies start working and then go quiet for a couple of weeks after which they proudly &lsquo;unveil&rsquo; your new website. Unfortunately, if you don't like what they've designed they will need to start again from scratch. This wastes time and is annoying for both developer and customer. At Moonspace, we make the website available from day one of the design process. In this way, you can check on the progress of your new website at any time.</p>

                <h3>Screen resolution &amp; &lsquo;responsive&rsquo; design</h3>

                <p>In these days of massive wide screen monitors, smart TVs and small mobile devices such as smart phones, a website needs to cater for all shapes and sizes. This is acheieved by using &lsquo;repsonsive design&rsquo;.</p>

                <p>Responsive design uses the same code for all devices but modifys it depending on the size of the device. In this way, a website will &lsquo;work&rsquo; on both a 40" wide screen monitor and a 4" smartphone. All Moonspace sites are now designed responsively so it's worthwhile taking some time to think about how you want your website to look like on a mobile device.</p>

                <p>The most important thing to remember is what you see on your computer may not be exactly what other people see.</p>

                <h3>Search Engine Optimisation</h3>

                <p>Simply put, Search Engine Optimisation (or SEO) is the process of getting your website to appear when people search for keywords. For example, if you sell bicycles, you'd like your site to appear at the top of the list when people search for 'buy bicycles'.</p>

                <p>The work involved in SEO starts as soon as the development starts. Major search engines check websites for valid code and rank those that are valid, higher than those that aren't. Moonspace <em>always</em> write valid code. The next step is to make the writing on the website 'keyword' rich. This means using words and phrases that you want searched for in the text on the website. Search engines analyse the site for keywords.</p>

                <p>After the design is complete, there are further steps that can be taken to optimise your website:</p>

                <ul>
                    <li>Register your site with search engines</li>
                    <li>Produce a 'site map' for search engines</li>
                    <li>If you have a shopping site, submit your products to Googles' shopping site</li>
                    <li>Installing reporting software to track the usage of your website</li>
                </ul>

                <p>Google is the 'main' search engine so <em>most</em> SEO is aimed at a high ranking in Google. There are however other search engines such as Yahoo, MSN &amp; AOL. Moonspace also design with these in mind and, during SEO submit sites to these search engines.</p>

                <p>A high ranking in any search engine can never be guaranteed. A lot can depend on the business you are in. If it is a competitive field (such as online book sales, insurance, auctions, etc.) there will be a lot of competition for the top slots. If, on the other hand, you are in a 'niche' field (such as permaculture, reindeer farming, thatching, etc.) a high ranking becomes much easier.</p>

                <h3>Time to live</h3>

                <p>Many people ask &lsquo;how long will it take to finish my website&rsquo; ? Simple static sites can be completed within a few weeks. Dynamic sites can take a few months, due to the complexity.</p>

                <p>For a website to be completed quickly, the following needs to be in place very early on:</p>

                <ul>
                    <li>A design</li>
                    <li>Copy - words for the website - you know your business, you're best suited to write the words !</li>
                    <li>The domain name</li>
                </ul>

                <p>And, as with all projects, communication is key. We at Moonspace will be available but we will need your feedback throughout the design process to speed things along.</p>

                <h3>Design Process</h3>

                <p>So, you've decided you want a website - now what ?</p>

                <p>Obviously the first thing to do is contact Moonspace on 07833 184038, <a href="/contact">via our contact form</a> or directly by email - <span className="emailInline"></span></p>

                <p>The design process will progress as below:</p>

                <ul>
                    <li>Initial contact</li>
                    <li>Client meeting</li>
                    <li>Proposal &amp; sample designs</li>
                    <li>Contract &amp; deposit</li>
                    <li>Design &amp; development (including feedback from you)</li>
                    <li>Website live and final payment</li>
                    <li>Search engine optimisation (if required)</li>
                </ul>

                <h3>And . . .</h3>

                <p>That's all there is to web development !</p>

                <p>If you've got any further questions, please contact us on 07833 184038 to discuss you requirements.</p>

                <p><em><strong>We look forward to working with you . . .</strong></em></p>
            </>
        )
    }
}

export default WebsiteBasics;
