// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { Link } from 'react-router-dom';
    import { scrollToTop } from '../../js/Functions';
// -----------------------------------------------------------------------------

class Home extends React.Component{
    render(){
        return(
            <>
                <p>MoonSpace has been building and maintaining websites for more than 10 years. We offer a full range of web design services from simple sites to complex, bespoke sites. We are based in Pembrokeshire in South West Wales.</p>

                <p>Our <Link to="/portfolio" >Portfolio</Link> showcases some of the sites we have produced in the past.</p>

                <p>We offer the following services:</p>

                <ul>
                    <li>A full web design service</li>
                    <li>A free heathcheck of your existing website</li>
                    <li>Mobile and tablet friendly websites</li>
                    <li>Hosting and maintenance of websites</li>
                    <li>Content management systems</li>
                    <li>Simple low cost websites</li>
                    <li>Complex bespoke websites</li>
                    <li>Shopping sites</li>
                    <li>Database driven websites</li>
                    <li>Credit card checkout integration</li>
                    <li>Help and advice from beginning to end</li>
                </ul>

                <h3><Link to="/contact" className="bold">Contact Us now</Link> for a FREE, no obligation consultation.</h3>

                <p>We can &lsquo;MOT&rsquo; your existing website and offer help and guidance to get it working for you !</p>

                <p>In order to 'demystify' the development process, we have produced a 'Website basics' guide to explain the basics of, not only websites, but also the development process of building a site.</p>

                <Link to="/websiteBasics" className="link icon-click" onClick={() => scrollToTop()}>Click here to view the basics of websites and development</Link>

                <p>
                    For my full CV and details of career and contracting, please visit
                    <a href="http://www.patrickdobson.co.uk" target="_blank">www.patrickdobson.co.uk</a>
                </p>
            </>
        )
    }
}

export default Home;
