// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import ReactDOM from 'react-dom';
    import { BrowserRouter, Switch, Route } from 'react-router-dom';
    import { createConsoleLog } from './js/Functions';
    import styles from './style/style';
    const appRoot = document.getElementById('app');
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
    import Header from './components/Header';
        import Home from './components/pages/Home';
        import About from './components/pages/About';
        import Services from './components/pages/Services';
        import Portfolio from './components/pages/Portfolio';
        import Contact from './components/pages/Contact';
        import Testimonials from './components/pages/Testimonials';
        import WebsiteBasics from './components/pages/WebsiteBasics';
        import Terms from './components/pages/Terms';
    import Footer from './components/Footer';
    import FooterText from './components/FooterText';
// -----------------------------------------------------------------------------


class App extends React.Component{
    constructor(props){
        super(props);
    }
    componentDidMount(){
        createConsoleLog();
    }
    render(){
        return(
            <>
                <Header />
                <div className="mainContent">
                	<div className="content" id="content">
                        <Switch>
                            <Route exact path="/"><Home /></Route>
                            <Route path="/about"><About /></Route>
                            <Route path="/services"><Services /></Route>
                            <Route path="/portfolio"><Portfolio /></Route>
                            <Route path="/contact"><Contact /></Route>
                            <Route path="/testimonials"><Testimonials /></Route>
                            <Route path="/websiteBasics"><WebsiteBasics /></Route>
                            <Route path="/terms"><Terms /></Route>
                        </Switch>
                        <div className="mobileFooter">
                            <FooterText />
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

// -- Render -------------------------------------------------------------------
    ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, appRoot )
// -----------------------------------------------------------------------------
