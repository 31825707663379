// -- Setup --------------------------------------------------------------------
    import React from 'react';
// -----------------------------------------------------------------------------

class Terms extends React.Component{
    render(){
        return(
            <>
                <h2>Terms and Conditions - Use of this website</h2>
                <ol>
                <li>By accessing this Website, you confirm that you are eligible (for example, a person of at least 16 years) to use this Website and you agree to be bound by all of the following terms and conditions of use (the “Terms”). These Terms govern your access to the Website except where there are separate terms and conditions relevant to particular areas of this Website, which are indicated on the appropriate Website page. If you do not agree to abide by these Terms then you should not use the Website.</li>
                <li>For clarity, in these Terms, references to “we”, “us”, “our” and “ourselves” are references to MoonSpace &amp; BroadstoneUK Ltd.</li>
                <li>Unless otherwise specified, the materials on this Website are directed solely at users who access this Website from the United Kingdom.</li>
                <li>All material on this Website including any advertising and/or promotional idents, images, text and/or audio is our property or has been licensed to us. All trade marks, names, logos and other intellectual property rights existing in this Website are also owned or licensed by us. You may use this Website for your personal information and you may make a copy of the pages of this Website but only for your personal non-commercial use, provided that you keep all copyright and other proprietary notices intact. Any modification, transmission, hiring, copying (other than for non-commercial personal use), reusing or otherwise using the content of this Website for public or commercial purposes is prohibited.</li>
                <li>Whilst we have worked diligently to provide accurate and complete information, we cannot be liable to any person for any loss or damage which may arise directly or indirectly from the use of this Website (or any inability to use it) or any of the information contained on this Website. This Website and the information and material which it contains are subject to change without notice. All warranties whether express or implied as to the accuracy or completeness of the information contained on this Website or in respect of any materials or products referred to on this Website are hereby excluded to the fullest extent permitted by law.</li>
                <li>Links on this Website and may lead to third party websites. The content, accuracy and function of such websites is outside of our control and we cannot accept any responsibility for them and nor do we endorse the contents of such third party websites. In particular, any dealings that you have with such third party website operators shall be on the terms and conditions (if any) of that website operator.</li>
                <li>Any communication or material which you transmit to us through this Website or otherwise via the Internet will be treated as non-confidential unless such communication or material includes personal information, which we will use in accordance with standard Privacy Policy guidelines.</li>
                </ol>

                <hr />

                <h1>Privacy Policy</h1>

                <p>We are committed to protecting your privacy. We will only use the information that we collect about you lawfully (in accordance with the Data Protection Act 1998).</p>

                <p>We only collect information about you for the purpose of direct contact to answer enquiries or aid in the completion of work.</p>

                <p>We will not e-mail you in the future unless you have given us your consent. Consent is inferred when you contact us.</p>

                <p>The type of information we will collect about you includes:</p>

                <ul>
                    <li>Your name</li>
                    <li>Telephone number</li>
                    <li>email address</li>
                </ul>

                <p>We will never collect sensitive information about you without your explicit consent.</p>

                <p>Any personal information which we hold will be held securely in accordance with our internal security policy and the law.</p>

                <p>If we intend to transfer your information outside the EEA (European Economic Area) we will always obtain your consent first.</p>

                <p>We may use technology to track the patterns of behaviour of visitors to our site. This can include using a &ldquo;cookie&rdquo; which would be stored on your browser. You can usually modify your browser to prevent this happening. The information collected in this way can be used to identify you unless you modify your browser settings.</p>

                <p>We will never sell or transfer your information to a third party</p>

                <hr />

                <h1>Terms and Conditions - Moonspace contracts</h1>

                <p>All clients are accepted in accordance with the following conditions unless expressly excluded or varied by agreement. Moonspace Web Design accepts no responsibility for errors or misunderstandings on details supplied by the client completed by the client.</p>

                <ol>
                    <li><strong>Definitions</strong><br />
                    In this Agreement the words 'we', 'us' and 'our' means Moonspace Web Design. 29 Kells Meend, Berry Hill, Gloucestershire. Gl16 7AD. The words 'you', 'your' and 'yours' refer to the person who entered into this agreement with us.</li>
                    <li><strong>Personal Details</strong>
                        <ol>
                            <li>These terms and conditions apply to all services supplied by we of Moonspace Web Design to you the customer named on the Design Specification Document. The Design Specification document and these conditions form the only legally binding agreement between us and are concluded by our delivery of service to you, or when we receive notification from you in writing that you no longer require our service.</li>
                            <li>Information provided in Design specification Document is valid for 28 days unless otherwise stated.</li>
                        </ol>
                    </li>
                    <li><strong>Payment</strong>
                        <ol>
                            <li>You are required to pay a deposit when the Design Specification is agreed upon and the document is signed. This will not be refunded under any circumstances.</li>
                            <li>Invoices are to be paid in sterling by any of the following methods:
                                <ul>
                                    <li>Cheque</li>
                                    <li>Through PayPal</li>
                                    <li>BACS transfer</li>
                                    <li>Credit/debit card</li>
                                </ul>
                            </li>
                            <li>Payment due within 14 days of invoice date.</li>
                        </ol>
                    </li>
                    <li><strong>Other charges</strong>
                        <ol>
                            <li>If you live outside a 25-mile radius of the above address we have the right to request travel expenses.</li>
                        </ol>
                    </li>
                    <li><strong>Delivery</strong>
                        <ol>
                            <li>Delivery of services is dependent upon the client paying the invoice in full. Delivery of services will be withheld if the full amount is not paid.</li>
                        </ol>
                    </li>
                    <li><strong>Cancellation of Services</strong>
                        <ol>
                            <li>You are entitled without giving reason to cancel your Design order provided you do so within 28 days of the first consultation and within 10 days of the Design Specification Document being signed.</li>
                            <li>Outside this time scale you will be charged a consultation fee of &pound;25.00 per hour for the work done up to that point. Deposits are non- refundable.</li>
                            <li>With regards to renewal of fees, the full amount is due within the stated invoice date. If the full amount is not paid it will be understood that the service is no longer required and said service will be terminated. We reserve the right to charge a consultation fee of &pound;25.00 per hour to re-instate any service, which has been terminated due to non-payment.</li>
                        </ol>
                    </li>
                    <li><strong>Intellectual Property / Copyright</strong>
                        <ol>
                            <li>All intellectual property rights remain with us unless a separate agreement is entered into.</li>
                            <li>All images, source code and designs remain property of us unless a separate agreement is entered into.</li>
                        </ol>
                    </li>
                    <li><strong>Responsibility</strong>
                        <ol>
                            <li>We are not legally responsible for any loss of earnings or loss of data as a result of use of the application/services we provide.</li>
                            <li>We are not legally responsible for any damage, defacement or loss of data due to hacking, virus's unsolicited email or power outages.</li>
                            <li>We reserve the right to contact any authority if we are concerned about anything that is found or discussed during a design/consultation process.</li>
                            <li>We are not liable to you or any of your customers for any financial loss due to credit card fraud.</li>
                        </ol>
                    </li>
                    <li><strong>Limitation of liability</strong>
                        <ol>
                            <li>This provision does not affect your statutory rights nor does it limit our liability for death, personal injury or fraudulent misrepresentation resulting from our negligence.</li>
                            <li>We will have no other liability except as set out above and in particular will not be liable to compensate you for disappointment, inconvenience, loss of profits, any indirect or consequential loss or damage whatsoever or howsoever occurring.</li>
                        </ol>
                    </li>
                    <li><strong>Unforeseen Circumstance</strong>
                        <ol>
                            <li>We shall not be liable to you for any failure by us to supply services caused by any event or circumstances beyond our reasonable control.</li>
                        </ol>
                    </li>
                    <li><strong>Application Law</strong>
                        <ol>
                            <li>This contract shall be governed by and interpreted in accordance with the Laws of England and subject to the non-exclusive jurisdiction of the English courts.</li>
                        </ol>
                    </li>
                </ol>


                <h2>Services provided with MoonSpace hosting packages</h2>

                <h3 className="bold">Introduction</h3>

                <p>These terms detail the provision of service that a third party can expect when receiving a hosting service from Moonspace. If you have agreed any further services (such as training, graphics production etc.) these will be covered in your specific contract.</p>

                <h3>Fault Recovery</h3>

                <p>In the event of a fault occurring that is NOT caused by the customer, Moonspace will make such changes to the site to make it fully operational.</p>

                <p>In the event of a fault that is caused by the customer, Moonspace will make such changes to the site to make it fully operational. Any further changes that need to be made to ensure the fault doesn't reoccur will be chargeable to the customer at the Moonspace standard hourly rate. If the customer chooses not to have the website modified, any further faults caused as a direct result will be charged at the Moonspace standard hourly rate.</p>

                <p>Moonspace cannot be held responsible for any loss of data from a database or other storage medium that occurs due to faults.</p>

                <h3>Updates</h3>

                <p>Moonspace will undertake minor changes to non-dynamic portions of the site (for example, front page text) when requested by the customer. </p>

                <p>Some examples of minor changes:</p>

                <ul>
                    <li>Minor text changes/spelling errors</li>
                    <li>Telephone number changes</li>
                    <li>Page title changes</li>
                    <li>Meta-Tag information changes</li>
                </ul>

                <p>Major changes will be charged to the customer at the Moonspace standard hourly rate.</p>

                <p>Some examples of major changes:</p>

                <ul>
                    <li>Overall style changes affecting the whole site (e.g. font colour/face change)</li>
                    <li>Changes to graphical elements (e.g. Logo changes)</li>
                    <li>Database changes</li>
                    <li>Addition of pages</li>
                </ul>

                <p>If the rate of site update exceeds more than 4 changes per month, on a regular basis Moonspace will propose a standing fee for future updates.</p>

                <p>Any updates that can be made through a supplied administration section are not the responsibility of Moonspace and, if performed by Moonspace will be charged at the Moonspace standard hourly rate.</p>

                <p>Some examples of chargeable updates:</p>

                <ul>
                    <li>Add a new range to a shopping site</li>
                    <li>Administer orders on a shopping site</li>
                    <li>Upload graphics for new or existing products</li>
                    <li>Forum administration</li>
                </ul>

                <h3>Domain Names</h3>

                <p>When Moonspace procure a domain name on behalf of a customer, it will be Moonspaces responsibility to alert the customer when the domain name(s) is due for renewal. Moonspace will only renew a domain name with the prior agreement of the customer. Moonspace will charge the standard rate for the renewal plus a small administration fee.</p>

                <p>When the customer supplies the domain name, Moonspace take no responsibility for the renewal or maintenance of that name.</p>

                <h2>Not Covered</h2>

                <h3>Search Engine Submission</h3>

                <p>Unless specifically agreed upon in a separate contract, Moonspace will not submit your site to any search engines and will not guarantee inclusion in any search engine. Although all Moonspace sites are designed to be fully visible to search engines, this is no guarantee of inclusion.</p>

                <h3>Server Errors</h3>

                <p>Moonspace rent hosting off a third party company and as such have no direct control over their servers. However, Moonspace use a high quality companies for their hosting who are contracted (to Moonspace) to do all in their power to keep the web servers running. All servers are backed up daily and uninterruptible power supplies back up mains power in the event of power loss.</p>

                <h3>Non Payment</h3>

                <p>In the event of non-payment of a hosting fee, Moonspace reserve the right to remove the hosted website from public view. The website will be removed on the first day of a non-payment period and will only be reinstated when the hosting fee has been paid.</p>

                <p>Moonspace charge for hosting on a yearly basis, in advance and will always alert customers at least 14 days in advance of a pending fee. If Moonspace fail to alert a customer of an impending hosting fee 14 days in advance, in the event of non-payment, the website will be removed one month after Moonspace alert the customer.</p>

                <h3>Repair and Maintenance</h3>

                <p>Moonspace can, at any time suspend a customers website for repair or maintenance. For many small issues it has been proven to be far quicker to rectify the fault without alerting the customer in advance.</p>

                <h2>Hosted website terms and conditions</h2>

                <ol>
                    <li>MoonSpace Design will not be liable for any loss or damage arising out of or in connection with the use of any hosted website</li>
                    <li>MoonSpace Design reserve the right to remove in part or in entirety any website or part of website that contravenes any law or measure of good taste. Examples of non-acceptable content include:
                        <ul>
                            <li>Pirated software or other media</li>
                            <li>Hacking programs or archives</li>
                            <li>Warez</li>
                            <li>Pornography</li>
                            <li>Spamming software</li>
                        </ul>
                    </li>
                    <li>Defamatory speech delivered over the internet can result in civil liability for the defamer</li>
                    <li>You must not modify or change any code hosted on a MoonSpace Design package to operate in connection with any Spam or other unsolicited email</li>
                    <li>MoonSpace design will not be liable for any shortcomings of customer equipment which may cause services or websites provided by MoonSpace Design to be inaccessible by the customer or any third party</li>
                    <li>MoonSpace Design accepts no liability for the acts or omissions of third party telecommunications or Internet Services providers for faults or failures of their apparatus</li>
                    <li>Unless specifically agreed employees of MoonSpace Design will not attend a customers premises under any circumstance</li>
                    <li>Websites hosted by MoonSpace Design may contain links to third party websites that are controlled and maintained by others. MoonSpace Design are not responsible in any way for such sites</li>
                    <li>MoonSpace Design cannot accept any responsibility for any interruption of service or loss of data due to server issues</li>
                    <li>All content designed by MoonSpace Design and Hosted in a MoonSpace Design hosting package, remains copyright MoonSpace Design unless specifically agreed in advance with the customer. All content supplied by the customer or a third party remains copyright of the originator</li>
                    <li>MoonSpace Design cannot accept any liability for any problem arising through customers failure to keep any usernames or passwords generated safe from third parties</li>
                    <li>You may cancel your web hosting at any time before your next billing cycle and you will not be billed for the next period</li>
                    <li>MoonSpace Design reserve the right to use the customer website for promotion of the business of MoonSpace Design unless otherwise directed by the customer</li>
                    <li>MoonSpace Design do not offer any kind of money back guarantee</li>
                    <li>These Terms and Conditions shall be governed by and construed in accordance with the law of England and you hereby submit to the exclusive jurisdiction of the English courts</li>
                    <li>Neither party is liable for any breach of this Agreement which is caused by a matter beyond its reasonable control including Acts of God, fire, lightening, explosion, war, disorder, flood, industrial disputes, extremely severe weather or acts of local or central Government or other competent authorities</li>
                    <li>Failure to comply with any of the terms and conditions will result in immediate termination of the website. Any work required to return the website to an operational condition will be charged at the MoonSpace Design standard hourly rate</li>
                </ol>
            </>
        )
    }
}

export default Terms;
