// -- Setup --------------------------------------------------------------------
    import React from 'react';
// -----------------------------------------------------------------------------

class Testimonials extends React.Component{
    render(){
        return(
            <>
                <h1>Testimonials</h1>

                <div className="testimonial">
                    <p>We found Moonspace by searching on the internet and after an initial meeting with him and a recommendation from someone else, we decided to go ahead with the build of our new website.</p>
                    <p>MoonSpace were very knowledgeable in the subject but didn't talk "techi" speak, and if they did, they were quick to explain to ordinary folk what they meant!</p>
                    <p>All instructions were dealt with quickly and efficiently and nothing seemed too much trouble, for what seemed a very complicated job to us and involved them speaking with a third party in our industry to gain further information.</p>
                    <p>MoonSpace went over and above our original expectations of the job specification and I would be happy to recommend MoonSpace to other businesses.</p>
                    <p>Thank you very much for all your hard work MoonSpace.</p>
                    <p><a href="http://www.officestar-group.co.uk" target="_blank">Sally Harrison - Office Star Group</a></p>
                </div>

                <div className="testimonial">
                    <p>I have found the whole experience of dealing with Moonspace very refreshing .Pat is a smashing chap to deal with and takes the time to listen and understand what it is your trying to create whilst providing professional advice and guidance on design and costs to help you achieve your goal of creating a site that reflects what you, your company and its services can provide.</p>
                    <p>I am very pleased to recommend Moonspace and hope to continue my relationship with them</p>
                    <p>James O'Grady - Right Height Access Services</p>
                </div>

                <div className="testimonial">
                    <p>MoonSpace has developed our website for an eco camping holiday site, although we have only started the business over the last couple of months we have received a lot of interest via the website, and great comments from users.</p>
                    <p>Pat is not just a web designer he is also committed to providing a good, friendly and expedient service, and cares about sustainability issues.</p>
                    <p>I have also worked with MoonSpace on a technical toolkit which was very complex and required a high level of programming knowledge.</p>
                    <p>Always very supportive as we are not super IT literate, keeps everything clear and user friendly.</p>
                    <p><a href="http://www.eco-camping.co.uk" target="_blank">Jane and Martin North Lodge Eco Holidays</a></p>
                </div>

                <div className="testimonial">
                    <p>Moonspace have helped me wonderfully move from an idea in my head to a reality online. Pat has always been very helpful and accommodating. The quality of their work is always spot on. Very happy to recommend moonspace every time.</p>
                    <p>Richard Bisiker - Personal Summits</p>
                </div>

                <div className="testimonial">
                    <p>The people I choose to work with in creating our music and getting our message across need to know where I'm coming from.</p>
                    <p>I want someone who genuinely cares about the world and the people in it. Pat does! He's efficient, intelligent and a great guy...</p>
                    <p><a href="http://www.seizetheday.org" target="_blank">Seize The Day</a></p>
                </div>

                <div className="testimonial">
                    <p>MoonSpace have created us a fantastic website,  that has enabled us to take full control of our business of holiday lettings, with a brilliant on-line booking system and easy to use administration even for those of us with limited pc skills.</p>
                    <p>Should we need support MoonSpace are always helpful and generous with their time and assistance and always have sound advice to offer. Since the launch of the website it has received much praise, and with business increasing it's exactly what we hoped for.</p>
                    <p>Anne Whittle - Sanderling Holiday Cottages</p>
                </div>

                <div className="testimonial">
                    <p>I was recommended to Moonscape by a friend, and I must say that I have not been disappointed, the ideas, enthusiasm and energy that has emanated from MoonSpace has assisted (and this is ongoing) with the whole nightmare of web design and the connection to the www, I could not  now be without them for all of my internet activities.</p>
                    <p>Thank you MoonSpace.</p>
                    <p><a href="http://www.franklynnevard.co.uk" target="_blank">Franklyn Nevard - Franklyn Nevard Associates</a></p>
                </div>

                <div className="testimonial">
                    <p>Moonspace have been really great and I very much appreciate their work on our behalf !</p>
                    <p><a href="http://www.lizroe-french.co.uk" target="_blank">Liz Roe-French, Astrologer/Healer Kingsbridge Devon.</a></p>
                </div>

                <div className="testimonial">
                    <p>We at Dave King fireplaces would like to say that since we have had Moonspace looking after our web page, we have had more enquiries than in the whole time the previous web page was on line. Excellent service. Well Done.</p>
                    <p>Dave King Fireplaces</p>
                </div>

                <div className="testimonial">
                    <p>MoonSpace are Fab! Great service...thankyou !</p>
                    <p>Brigit Strawbridge - It's not easy being green</p>
                </div>

                <div className="testimonial">
                    <p>Moonspace were really helpful from the start, with a professional, friendly and reliable service. Its really good to be able to edit my own site thanks to their excellent training.</p>
                    <p><a href="http://www.davidsnowdon.co.uk" target="_blank">David Snowdon - David Snowdon Furniture</a></p>
                </div>
            </>
        )
    }
}

export default Testimonials;
