// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { Link } from 'react-router-dom';
    import { initialiseContact } from '../../js/Functions';
// -----------------------------------------------------------------------------

class Contact extends React.Component{
    componentDidMount(){
        initialiseContact();
    }
    render(){
        return(
            <>
                <h1>Contact</h1>

                <div className="grid">
                    <div className="col-1-2">
                        <div className="contactForm">
                            <p>
                                <label htmlFor="contactName">Name</label>
                                <input type="text" name="contactName" id="contactName" />
                            </p>
                            <p className="antiSpam">
                                <label htmlFor="antiSpam">Leave this field blank</label>
                                <input type="text" name="antiSpam" id="antiSpam" />
                            </p>
                            <p>
                                <label htmlFor="contactEmail">Email</label>
                                <input type="email" name="contactEmail" id="contactEmail" />
                            </p>
                            <p>
                                <label htmlFor="contactTel">Telephone</label>
                                <input type="tel" name="contactTel" id="contactTel" />
                            </p>
                            <p>
                                <label htmlFor="message">Message</label>
                                <textarea name="message" id="message"></textarea>
                            </p>
                            <a className="formButton js_sendMessage">Send message</a>
                        </div>
                        <div className="contactMessage">
                            <h3>Thank you for contacting Moonspace</h3>
                            <p>We aim to reply to all messages within three working days.</p>
                            <p>If your enquiry is more urgent, please call us on 07833 184038.</p>
                        </div>
                    </div>
                    <div className="col-1-2 contactDetails">
                        <p className="emailIcon"><a href="mailto:info@moonspace.co.uk" className="icon-email">info@moonspace.co.uk</a></p>
                        <p className="icon-telephone"><a href="tel:07833184038">07833 184038</a></p>
                        <p className="icon-address">Crugiau Isaf, Pentregalar, Crymych, Pembrokeshire, SA41 3QP</p>
                        <p>All information collected is stored in accordance with our <Link to="/terms">privacy policy</Link>.</p>
                    </div>
                </div>
            </>
        )
    }
}

export default Contact;
