// -- Setup --------------------------------------------------------------------
    import React from 'react';
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
    import FooterText from './FooterText';
// -----------------------------------------------------------------------------

class Footer extends React.Component{
    render(){
        return(
            <div className="footer">
                <FooterText />
            </div>
        )
    }
}

export default Footer;
