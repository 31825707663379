
// -- Create console log ----------------------------------
	const createConsoleLog = () => {

		var ua = window.navigator.userAgent;
		var isIE = ua.indexOf('MSIE');
		var isIE11 = ua.match(/Trident.*rv\:11\./);
		var ie = false;
		if( isIE !== -1 || isIE11 !== null ){
			ie = true;
		}

		if( !ie ){
			var header = 'font-family: Helvetica, Arial, sans-serif; background: #a2b027; padding: 6px 4px 8px 4px; color: #ffffff; font-size: 30px; border-radius: 4px';
			var text = 'font-family: Helvetica, Arial, sans-serif; font-size: 17px; color: #232323';
			var link = text + 'color: blue';
			var b = '';
			console.log( '\n\n%c Development by Moonspace %c \n\n%c Check out %chttp://www.moonspace.co.uk%c for websites\n and %chttp://www.patrickdobson.co.uk%c for contracting \n\n', header, b, text, link, text, link, text);
		}
	}
// --------------------------------------------------------

// -- Scroll to top of 'content' div -------------------------------------------
    const scrollToTop = () => {
        document.getElementById('content').scrollTo(0, 0);
	        const mobileMenu = document.getElementsByClassName('mobileMenu')[0].children[1];
	        mobileMenu.style.display = 'none';
    }
// -----------------------------------------------------------------------------

// -- Contact ------------------------------------------------------------------
	const initialiseContact = () => {
		const axios = require('axios').default;
		const elements = document.getElementsByClassName('js_sendMessage');
		Array.from(elements).forEach(function(element){
			element.addEventListener('click', submitForm);
		})
		function submitForm(e){
			e.preventDefault();
				let antiSpam, contactName, contactEmail, contactTel, message, contactData = [], sendMail = true;
				antiSpam = document.getElementById('antiSpam').value;
				contactName = document.getElementById('contactName').value;
				contactEmail = document.getElementById('contactEmail').value;
				contactTel = document.getElementById('contactTel').value;
				message = document.getElementById('message').value;
				contactData = {
					'contactName' : contactName,
					'contactEmail' : contactEmail,
					'contactTel' : contactTel,
					'message' : message
				};
				if( (contactName == '' && contactEmail == '' && contactTel == '' & message == '') || antiSpam != '' ){
					sendMail = false;
				}
				if( sendMail ){
					axios.post('contactEmail.php', contactData)
						.then(
							data => {
								document.getElementsByClassName('contactForm')[0].style.display = 'none';
								document.getElementsByClassName('contactMessage')[0].style.display = 'block';
							}
						)
				}
		}
	}
// -----------------------------------------------------------------------------

export { createConsoleLog, scrollToTop, initialiseContact };
