// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { Link } from 'react-router-dom';
    import { scrollToTop } from '../js/Functions';
// -----------------------------------------------------------------------------


class Menu extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <ul>
                <li><Link to="/about" onClick={() => scrollToTop()}>About</Link></li>
                <li><Link to="/services" onClick={() => scrollToTop()}>Services</Link></li>
                <li><Link to="/portfolio" onClick={() => scrollToTop()}>Portfolio</Link></li>
                <li><Link to="/contact" onClick={() => scrollToTop()}>Contact</Link></li>
                <li><Link to="/testimonials" onClick={() => scrollToTop()}>Testimonials</Link></li>
                <li><Link to="/" onClick={() => scrollToTop()}>Home</Link></li>
            </ul>
        )
    }
}

export default Menu;
