// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { Link } from 'react-router-dom';
// -----------------------------------------------------------------------------

class Services extends React.Component{
    render(){
        return(
            <>
                <h1>Services</h1>

                <p>MoonSpace offers a wide range of services including the following :</p>

                <ul>
                    <li>PHP based dynamic website design &amp; development</li>
                    <li>Rich Media Internet Applications</li>
                    <li>Static website design</li>
                    <li>Web hosting</li>
                    <li>Secure e-commerce integration</li>
                    <li>Integration into third party payment systems such as PayPal &amp; SagePay</li>
                    <li>Integration of XML and RSS feeds (such as blog headlines or news)</li>
                    <li>Social media integration</li>
                </ul>

                <p>For more specific requirements, <Link to="/contact">please contact us</Link>.</p>

                <p>All MoonSpace websites are developed to be compliant with current Internet standards and accessibility legislation. MoonSpace ensure that all their websites are optimized for inclusion in search engines.</p>

                <p>All full hosting packages can include the following:</p>
                <ul>
                    <li>Unlimited bandwidth</li>
                    <li>Unlimited email accounts</li>
                    <li>Online traffic analysis</li>
                    <li>SSL Secure sites</li>
                    <li>Dedicated FTP accounts</li>
                </ul>

                <p>I am also available for long and short term contracts.</p>

                <p>For my full CV and details of career and contracting, please visit <a href="http://www.patrickdobson.co.uk" target="_blank">www.patrickdobson.co.uk</a></p>
            </>
        )
    }
}

export default Services;
