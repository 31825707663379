// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { Link } from 'react-router-dom';
// -----------------------------------------------------------------------------

class Portfolio extends React.Component{
    render(){
        return(
            <>
                <h1>Portfolio</h1>

                {/* -- Berry Bros. & Rudd -- */}
                <div className="grid portfolio">
                    <div className="col-1-1 portfolioInner">
                        <h3>Berry Bros. &amp; Rudd</h3>
                        <a href="http://www.bbr.com" target="_blank" className="link icon-click">View site</a>
                        <div className="col-1-3">
                            <img src="/images/portfolio/bbr.jpg" alt="Berry Bros. &amp; Rudd" />
                        </div>
                        <div className="col-1-3">
                            <p>Berry Bros. &amp; Rudd are a long established wine merchant/retailer based in central London.</p>
                        </div>
                        <div className="col-1-3">
                            <p>I created the front end components for this site, working with a team of Java/Hybris developers who created the back end</p>
                            <p>The website offers the following features:</p>
                            <ul>
                                <li>A full content management system</li>
                                <li>Members only facilities</li>
                                <li>Members only facilities include editing own profile, and wine marketplace</li>
                                <li>A fully responsive design that works for large desktop monitors down to small screen smart phones</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* -- Broadstone Rare Breeds -- */}
                <div className="grid portfolio">
                    <div className="col-1-1 portfolioInner">
                        <h3>Broadstone Rare Breeds</h3>
                        <a href="https://www.broadstonerarebreeds.co.uk" target="_blank" className="link icon-click">View site</a>
                        <div className="col-1-3">
                            <img src="/images/portfolio/broadstonerarebreeds.jpg" alt="Broadstone Rare Breeds" />
                        </div>
                        <div className="col-1-3">
                            <p>Broadstone Rare Breeds are a farm based in Pembrokeshire, Wales.</p>
                        </div>
                        <div className="col-1-3">
                            <p>The website offers the following features:</p>
                            <ul>
                                <li>A fast, single page app written in React</li>
                                <li>Easily updatable via a configuration file</li>
                                <li>Integration of the Broadstone Etsy shop products</li>
                                <li>A fully responsive design that works for large desktop monitors down to small screen smart phones</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* -- My voucher codes -- */}
                <div className="grid portfolio">
                    <div className="col-1-1 portfolioInner">
                        <h3>MyVoucherCodes</h3>
                        <a href="http://www.myvouchercodes.co.uk" target="_blank" className="link icon-click">View site</a>
                        <div className="col-1-3">
                            <img src="/images/portfolio/myvouchercodes.jpg" alt="MyVoucherCodes" />
                        </div>
                        <div className="col-1-3">
                            <p>MyVoucherCodes are a discount code aggregator based in central London. They are part of the GoCompare group.</p>
                        </div>
                        <div className="col-1-3">
                            <p>I created the front end components for this site, working with a team of PHP developers who created the back end</p>
                            <p>The website offers the following features:</p>
                            <ul>
                                <li>A full content management system</li>
                                <li>Members only facilities</li>
                                <li>'Lazy loading' enabling page content to appear only when the user scrolls down the site</li>
                                <li>A fully responsive design that works for large desktop monitors down to small screen smart phones</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* -- Prudential -- */}
                <div className="grid portfolio">
                    <div className="col-1-1 portfolioInner">
                        <h3>Prudential</h3>
                        <div className="col-1-3">
                            <img src="/images/portfolio/prudential.jpg" alt="Prudential" />
                        </div>
                        <div className="col-1-3">
                            <p>Prudential are a very well known pension provider company based in central London</p>
                        </div>
                        <div className="col-1-3">
                            <p>I created a series of financial calculators for Prudential, working with a team of financial experts (Actuaries)</p>
                            <p>The calculators developed include:</p>
                            <ul>
                                <li>Income tax and tax relief calculator</li>
                                <li>Emergency tax tool</li>
                                <li>Annual allowance calculator</li>
                                <li>Salary exchange calculator</li>
                                <li>Available funds and preferential terms calculator</li>
                                <li>Application generation tool</li>
                            </ul>
                            <p>Click <a href="https://www.pru.co.uk/tools-calculators/" className="inlineLink" target="_blank">Here</a> and <a href="https://www.pruadviser.co.uk/tools-calculators/" className="inlineLink" target="_blank">Here to view the prudential sites</a></p>
                        </div>
                    </div>
                </div>

                {/* -- Contracting -- */}
                <div className="grid portfolio">
                    <div className="col-1-1 portfolioInner">
                        <h3>Contracting</h3>
                        <p>As part of my web development career I have also contracted for some large companies including Prudential, nPower, Wex, Dyson, BMW &amp; GoCompare.</p>
                        <p>Full techinical details of these contracts can be found <a href="https://www.patrickdobson.co.uk" className="inlineLink" target="_blank">here</a></p>
                    </div>
                </div>

                {/* -- Devon and Cornwall flockbook -- */}
                <div className="grid portfolio">
                    <div className="col-1-1 portfolioInner">
                        <h3>Devon and Cornwall longwool association</h3>
                        <a href="http://www.devonandcornwallflockbook.co.uk" target="_blank" className="link icon-click">View site</a>
                        <div className="col-1-3">
                            <img src="/images/portfolio/devonandcornwallflockbook.jpg" alt="Devon and cornwall longwool association" />
                        </div>
                        <div className="col-1-3">
                            <p>The Devon and Cornwall longwool association is the official body responsible for ensuring the future of this sheep breed.</p>
                        </div>
                        <div className="col-1-3">
                            <p>The website offers the following features:</p>
                            <ul>
                                <li>A full content management system</li>
                                <li>Members only facilities include editing own profile, adding 'for sale' items, association newsletters, useful information and lamb birth registration</li>
                                <li>A fully responsive design that works for large desktop monitors down to small screen smart phones</li>
                                <li>A show calendar with website and map links</li>
                                <li>A 'for sale' section where members can place classified adverts</li>
                                <li>A list of breeders with links for email, website, facebook and twitter</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* -- Dallas Masters -- */}
                <div className="grid portfolio">
                    <div className="col-1-1 portfolioInner">
                        <h3>Dallas Masters</h3>
                        <a href="http://www.dallasmasters.co.uk" target="_blank" className="link icon-click">View site</a>
                        <div className="col-1-3">
                            <img src="/images/portfolio/dallas-masters.jpg" alt="Dallas Masters" />
                        </div>
                        <div className="col-1-3">
                            <p>Dallas Masters are an audio mastering studio based in Nottingham.</p>
                        </div>
                        <div className="col-1-3">
                            <p>The website offers the following features:</p>
                            <ul>
                                <li>A professional 'dark' design</li>
                                <li>Bold contact links</li>
                                <li>An easy to use contact link</li>
                                <li>A link to a Google location map</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* -- David Snowdon -- */}
                <div className="grid portfolio">
                    <div className="col-1-1 portfolioInner">
                        <h3>David Snowdon Furniture</h3>
                        <a href="http://www.davidsnowdon.co.uk" target="_blank" className="link icon-click">View site</a>
                        <div className="col-1-3">
                            <img src="/images/portfolio/davidSnowdon.jpg" alt="David Snowdon Furniture" />
                        </div>
                        <div className="col-1-3">
                            <p>David Snowdon is a bespoke furniture maker based in the Forest of Dean. The wesbite showcases David's work via a number of image galleries.</p>
                            <p>A 'slide-in' gallery and random images add some animation to the front page.</p>
                            <p>The site was designed to make it easy for the customer to update without the need for an expensive content management system.</p>
                        </div>
                        <div className="col-1-3">
                            <p>The website offers the following features:</p>
                            <ul>
                                <li>An easy to use contact form</li>
                                <li>Client testimonials page</li>
                                <li>Simple code design to offer easy updating</li>
                                <li>Image galleries</li>
                                <li>Sliding image marquee</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* -- Eco camping -- */}
                <div className="grid portfolio">
                    <div className="col-1-1 portfolioInner">
                        <h3>Northlodge</h3>
                        <a href="http://www.eco-camping.co.uk" target="_blank" className="link icon-click">View site</a>
                        <div className="col-1-3">
                            <img src="/images/portfolio/ecoCamping.jpg" alt="Northlodge" />
                        </div>
                        <div className="col-1-3">
                            <p>Northlodge is a green, sustainable campsite in south west Wales which offers camping, log cabins and group bookings.</p>
                        </div>
                        <div className="col-1-3">
                            <p>The website offers the following features:</p>
                            <ul>
                                <li>An easy to use contact form</li>
                                <li>Link to a personalised Google map</li>
                                <li>Original design</li>
                                <li>Fully responsive - ready for mobile and tablets</li>
                                <li>Integrated with 'Cool Camping' for online bookings and reservations</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* -- Journeyman -- */}
                <div className="grid portfolio">
                    <div className="col-1-1 portfolioInner">
                        <h3>Journeyman Services</h3>
                        <a href="http://www.journeyman-services.com" target="_blank" className="link icon-click">View site</a>
                        <div className="col-1-3">
                            <img src="/images/portfolio/journeyman.jpg" alt="Journeyman Services" />
                        </div>
                        <div className="col-1-3">
                            <p>Journeyman Services are an insurance company offering medical and travel insurance to the ex-pat community worldwide.</p>
                        </div>
                        <div className="col-1-3">
                            <p>The website offers the following features:</p>
                            <ul>
                                <li>An interactive insurance calculator</li>
                                <li>Complete online quoting and purchasing of insurance</li>
                                <li>Delivery of insurance documentation</li>
                                <li>Integration with SagePay for online purchases and invoice payments</li>
                                <li>Private administration allowing the site owner to update pricing and other aspects of the calculation</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* -- Liz Roe French -- */}
                <div className="grid portfolio">
                    <div className="col-1-1 portfolioInner">
                        <h3>Liz Roe French</h3>
                        <a href="http://www.lizroe-french.co.uk" target="_blank" className="link icon-click">View site</a>
                        <div className="col-1-3">
                            <img src="/images/portfolio/lizRoeFrench.jpg" alt="Liz Roe French" />
                        </div>
                        <div className="col-1-3">
                            <p>Liz Roe-French is a therapist with 30 years experience in the field. She gives astrological readings, offers therapies, runs workshops and presents seminars.</p>
                        </div>
                        <div className="col-1-3">
                            <p>The website offers the following features:</p>
                            <ul>
                                <li>A 'shop window' and central point of contact on the internet</li>
                                <li>Download documents throughout the site</li>
                                <li>Mailing list subscription</li>
                                <li>Contact form and complete contact details</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* -- Mike Barber -- */}
                <div className="grid portfolio">
                    <div className="col-1-1 portfolioInner">
                        <h3>Mike Barber</h3>
                        <a href="http://www.mikebarber.co.uk" target="_blank" className="link icon-click">View site</a>
                        <div className="col-1-3">
                            <img src="/images/portfolio/mikeBarber.jpg" alt="Mike Barber" />
                        </div>
                        <div className="col-1-3">
                            <p>A website for a bass guitar player based in Reading.</p>
                            <p>The website allows visitors to listen to songs online without allowing them to be downloaded to the vistors computer.</p>
                        </div>
                        <div className="col-1-3">
                            <p>The website offers the following features:</p>
                            <ul>
                                <li>A biography section</li>
                                <li>Details of bands, past and present</li>
                                <li>Contact details and a contact form</li>
                                <li>Technical musical details of Mike's bass setup</li>
                                <li>A selection of past and present songs</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* -- North Lodge -- */}
                <div className="grid portfolio">
                    <div className="col-1-1 portfolioInner">
                        <h3>Northlodge bed &amp; breakfast</h3>
                        <a href="http://www.northlodgewales.co.uk" target="_blank" className="link icon-click">View site</a>
                        <div className="col-1-3">
                            <img src="/images/portfolio/northLodge.jpg" alt="Northlodge bed and breakfast" />
                        </div>
                        <div className="col-1-3">
                            <p>Northlodge is an eco friendly bed &amp; breakfast based in south west Wales.</p>
                        </div>
                        <div className="col-1-3">
                            <p>The website offers the following features:</p>
                            <ul>
                                <li>A fresh design whch reflects the green credentials of the business</li>
                                <li>A lightbox based image gallery</li>
                                <li>An easy to use contact form</li>
                                <li>Bold and easy to read contact details on every page</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* -- Park that bike - info -- */}
                <div className="grid portfolio">
                    <div className="col-1-1 portfolioInner">
                        <h3>ParkThatBike - public site</h3>
                        <a href="http://www.parkthatbike.info" target="_blank" className="link icon-click">View site</a>
                        <div className="col-1-3">
                            <img src="/images/portfolio/parkthatbike_info.jpg" alt="Park That Bike" />
                        </div>
                        <div className="col-1-3">
                            <p>ParkThatBike is an independent transport consultancy that works with local authorities, government agencies and major employers.</p>
                        </div>
                        <div className="col-1-3">
                            <p>The website offers the following features:</p>
                            <ul>
                                <li>A content management system allowing the site owner to update the site</li>
                                <li>A completely responsive design, optimised for both desktop viewing and mobile</li>
                                <li>Bold full screen image backgrounds</li>
                                <li>An interactive multipart application process allowing visitors to apply for free cycle parking</li>
                                <li>Social media integration</li>
                                <li>A full online store integrated with PayPal to provide secure shopping</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* -- Park that bike - com -- */}
                <div className="grid portfolio">
                    <div className="col-1-1 portfolioInner">
                        <h3>ParkThatBike - corporate site</h3>
                        <a href="http://www.parkthatbike.com" target="_blank" className="link icon-click">View site</a>
                        <div className="col-1-3">
                            <img src="/images/portfolio/parkThatBike_com.jpg" alt="Park That Bike" />
                        </div>
                        <div className="col-1-3">
                            <p>ParkThatBike is an independent transport consultancy that works with local authorities, government agencies and major employers.</p>
                        </div>
                        <div className="col-1-3">
                            <p>The website offers the following features:</p>
                            <ul>
                                <li>A content management system allowing the site owner to update the site</li>
                                <li>A completely responsive design, optimised for both desktop viewing and mobile</li>
                                <li>Bold full screen image backgrounds</li>
                                <li>An image gallery 'slider' which gives access to a large amount of images in a small space</li>
                                <li>Social media integration</li>
                                <li>A news section which draws its content from the CMS</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Portfolio;
