// -- Setup --------------------------------------------------------------------
    import React from 'react';
    import { Link } from 'react-router-dom';
// -----------------------------------------------------------------------------

// -- Components ---------------------------------------------------------------
    import Menu from './Menu';
// -----------------------------------------------------------------------------

class Header extends React.Component{
    constructor(props){
        super(props);
        this.mobileMenu = this.mobileMenu.bind(this);
    }
    mobileMenu(){
        const mobileMenu = document.getElementsByClassName('mobileMenu')[0].children[1];
        mobileMenu.style.display = 'block';
    }
    render(){
        return(
            <div className="header">
                <h1><Link to="/">Moonspace</Link></h1>
                <div className="menu">
            		<Menu />
                </div>
                <div className="mobileMenu">
            		<i className="icon-menu" onClick={this.mobileMenu}></i>
            		<Menu />
                </div>
                <a href="tel:07833184038" className="telephone icon-telephone">07833 184038</a>
            </div>
        )
    }
}

export default Header;
